import * as React from "react";

const Holidays = () => {
  return (
    <div className="max-w-screen-xl mx-auto py-12 lg:py-24">
      <div className="px-4">
        <h2 className="text-greenDark text-3xl text-center font-light font-quick tracking-tight">
          Academic Calendar and Holidays 2021-2022
        </h2>
        <div className="mt-10 lg:mt-14">
          <table className="min-w-full">
            <tr className="text-center border border-greenDark divide-x divide-greenDark">
              <td className="p-3 text-dark font-light">Monday, September 6</td>
              <td className="p-3 text-dark font-light">
                Labour Day – School Closed
              </td>
            </tr>
            <tr className="text-center border border-greenDark divide-x divide-greenDark">
              <td className="p-3 text-dark font-light">Tuesday, September 7</td>
              <td className="p-3 text-dark font-light">First Day of School</td>
            </tr>
            <tr className="text-center border border-greenDark divide-x divide-greenDark">
              <td className="p-3 text-dark font-light">Monday, October 11</td>
              <td className="p-3 text-dark font-light">
                Thanksgiving Day – School Closed
              </td>
            </tr>

            <tr className="text-center border border-greenDark divide-x divide-greenDark">
              <td className="p-3 text-dark font-light">Friday, December 17</td>
              <td className="p-3 text-dark font-light">
                Last Day of School before winter / Christmas Break
              </td>
            </tr>
            <tr className="text-center border border-greenDark divide-x divide-greenDark">
              <td className="p-3 text-dark font-light">
                Monday, December 20, 2021 to December 31, 2021
              </td>
              <td className="p-3 text-dark font-light">
                Winter/Christmas Break- School Closed
              </td>
            </tr>
            <tr className="text-center border border-greenDark divide-x divide-greenDark">
              <td className="p-3 text-dark font-light">Monday, January 3</td>
              <td className="p-3 text-dark font-light">School Opens</td>
            </tr>

            <tr className="text-center border border-greenDark divide-x divide-greenDark">
              <td className="p-3 text-dark font-light">Monday, February 21</td>
              <td className="p-3 text-dark font-light">
                Family Day – School Closed
              </td>
            </tr>
            <tr className="text-center border border-greenDark divide-x divide-greenDark">
              <td className="p-3 text-dark font-light">March 14 to March 18</td>
              <td className="p-3 text-dark font-light">
                March break – School Closed
              </td>
            </tr>
            <tr className="text-center border border-greenDark divide-x divide-greenDark">
              <td className="p-3 text-dark font-light">Friday April 15</td>
              <td className="p-3 text-dark font-light">
                Good Friday – School Closed
              </td>
            </tr>
            <tr className="text-center border border-greenDark divide-x divide-greenDark">
              <td className="p-3 text-dark font-light">Monday, April 18</td>
              <td className="p-3 text-dark font-light">
                Easter Monday – School Closed
              </td>
            </tr>
            <tr className="text-center border border-greenDark divide-x divide-greenDark">
              <td className="p-3 text-dark font-light">Monday, May 23</td>
              <td className="p-3 text-dark font-light">
                Victoria Day – School Closed
              </td>
            </tr>
            <tr className="text-center border border-greenDark divide-x divide-greenDark">
              <td className="p-3 text-dark font-light">Friday June 24</td>
              <td className="p-3 text-dark font-light">
                Last Day of School before Summer Break
              </td>
            </tr>
          </table>
          <p className="font-bold text-sm mt-5">
            Please Note: The school offers an eight week Summer Program during
            July/August
          </p>
        </div>
      </div>
    </div>
  );
};

export default Holidays;
