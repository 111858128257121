import React from "react";
import Header from "../components/calendar/header";
import Holidays from "../components/calendar/holidays";
import Layout from "../components/layout";

const Calendar = () => {
  return (
    <Layout pageTitle="Calendar - Forest Grove Montessori Pre-School">
      <Header />
      <Holidays />
    </Layout>
  );
};

export default Calendar;
